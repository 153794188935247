import { setUser } from '@sentry/browser'
import { useFormik } from 'formik'
import { KeyboardEvent, useCallback, useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { IPopulatePreRegisterInfo } from 'types'
import * as Yup from 'yup'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { ParamsContext } from '~/contexts/ParamsContext'
import { errorMessages, requiredErrorMessages } from '~/enums/defaultMessages'
import { LocalStorageKeys } from '~/enums/index'
import { useUserData } from '~/hooks/useUserData'
import { createCreditRequest } from '~/services/api'
import { addMask } from '~/utils/addMask'
import { changeRouteKeepParams } from '~/utils/changeRouteKeepParams'
import { getCRID } from '~/utils/getCRID'
import { removeMask } from '~/utils/removeMask'
import { validateCPF } from '~/utils/validation'

export const usePreRegistration = () => {
  const { userData } = useUserData()

  const {
    setVerificationInfo,
    isSendingData,
    setIsSendingData,
    setStep,
    partnerId,
    setAlreadyHasActiveProductType,
    basicInfoData,
  } = useContext(CheckoutContext)
  const { getParams, router, partnerSlug, route, productType } = useContext(ParamsContext)
  const [isChecked, setIsChecked] = useState(false)
  const [isCaptchaValid, setIsCaptchaValid] = useState(false)

  useEffect(() => {
    window?.grecaptcha?.execute?.()
  }, [isChecked])

  const onRecaptchaChange = useCallback((token: string) => setIsCaptchaValid(!!token), [setIsCaptchaValid])

  const currentRoute = route === '/' || route === '/courses' || route === '/checkout'

  const proceed = useCallback(
    async (preRegistrationValues) => {
      if (!isChecked) {
        toast.error('Você precisa aceitar os termos de uso', {
          toastId: 'tos-error',
        })
        return
      }

      window?.smartlook?.('consentAPI', 'Li e estou de acordo com os Termos de uso e a Política de privacidade da Principia.')

      setIsSendingData(true)
      const removeCPFMask = removeMask(preRegistrationValues.cpf) as string
      const classes = JSON.parse(`[${getParams().classes || null}]`)
      const { token, campaign, seller } = getParams()

      const response = await createCreditRequest({
        CPF: removeCPFMask,
        classArray: classes,
        PartnerId: partnerId,
        token,
        campaignSlug: campaign,
        SellerId: seller,
        type: productType,
      })

      // @ts-ignore
      if (response?.response?.data?.error) {
        // @ts-ignore
        toast.error(response?.response?.data?.errors?.[0]?.message || 'Tente novamente mais tarde', {
          toastId: 'tos-error',
        })
        return
      }

      if (typeof response !== 'string') {
        const crid = getCRID(response?.token)?.toString()
        setAlreadyHasActiveProductType(response?.alreadyHasActiveProductType)

        if (crid) {
          window?.smartlook?.('identify', crid)

          setUser?.({
            crid,
          })
        }

        sessionStorage.setItem(LocalStorageKeys.user_token, response.token || '')
        response.isPendingSettlement &&
          sessionStorage.setItem(LocalStorageKeys.isPendingSettlement, response.isPendingSettlement.toString() || '')

        if (['verificationScreen', 'checkoutEmailVerification'].includes(response.nextScreen)) {
          setVerificationInfo({
            email: response.email,
            phone: response.phone,
          })

          currentRoute && response?.nextScreen && setStep(response.nextScreen)
        }

        if (response.nextScreen === 'courses') {
          router.push(changeRouteKeepParams(router.asPath, `/courses/${partnerSlug}`))
          setIsSendingData(false)
          return
        }

        setIsSendingData(false)
      }
    },
    [
      isChecked,
      getParams,
      setVerificationInfo,
      setIsSendingData,
      partnerSlug,
      router,
      currentRoute,
      setStep,
      partnerId,
      productType,
      setAlreadyHasActiveProductType,
    ],
  )

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    dirty,
    setFieldValue,
    validateForm,
    setFieldTouched,
    isSubmitting,
  } = useFormik({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      cpf: '',
    },
    validationSchema: Yup.object({
      cpf: Yup.string().required(requiredErrorMessages.cpf).min(14, errorMessages.cpf),
    }),

    onSubmit: proceed,

    validate: (fields: IPopulatePreRegisterInfo) => {
      const fieldsArray = Object.keys(fields)
      return fieldsArray.reduce((prev, cur) => {
        if (cur === 'cpf' && fields[cur] && fields[cur].length === 14 && !validateCPF(fields[cur])) {
          return {
            ...prev,
            [cur]: errorMessages.invalidCPF,
          }
        }
        return { ...prev }
      }, {})
    },
  })

  const handleEnterKey = (event: KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      !isSubmitting && handleSubmit(event)
    }
  }

  const handleClickButtonVariant = useCallback(() => {
    if (router.query.slug) {
      router.push(changeRouteKeepParams(router.asPath, `/courses/${partnerSlug}`))
    } else {
      window.open('https://provi.com.br/como-funciona', '_blank')
    }
  }, [router, partnerSlug])

  useEffect(() => {
    if (userData?.CPF) {
      setFieldValue('cpf', addMask(userData?.CPF, '###.###.###-##'))
    }

    if (basicInfoData?.CPF) {
      setFieldValue('cpf', addMask(basicInfoData?.CPF, '###.###.###-##'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicInfoData?.CPF, userData?.CPF])

  return {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    setFieldValue,
    handleBlur,
    isValid,
    dirty,
    handleEnterKey,
    validateForm,
    setFieldTouched,
    isChecked,
    setIsChecked,
    isSendingData,
    handleClickButtonVariant,
    onRecaptchaChange,
    isCaptchaValid,
  }
}
