import styled from 'styled-components'
import { theme } from '@provi/provi-components'
import { H4, loadingCSS } from '~/styles/global'

interface ITitleContent {
  isSuccess?: boolean
}

export const FeedbackHeader = styled.div<ITitleContent>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 164px;
  background: ${({ isSuccess }) => (isSuccess ? theme.colors.bluePrimary : theme.colors.pink900)};
  border-radius: 8px 8px 0px 0px;
  padding: 13px 24px;

  > h4 {
    margin-top: 11px;
  }

  ${theme.breakpoints.down('md')} {
    border-radius: 0;
  }
`

export const FeedbackTitle = styled(H4)`
  color: ${theme.colors.white100};
`

export const TextLoading = styled.div`
  ${loadingCSS}
  height: 30px;
  width: 100%;
  margin-top: 10px;
`
