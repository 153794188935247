import * as S from './style'
import { SuccessIcon, FailureIcon } from '~/components/atoms'

interface IFeedbackHeaderCheckout {
  title: string
  isSuccess?: boolean
}

const FeedbackHeaderCheckout = ({ title, isSuccess }: IFeedbackHeaderCheckout) => (
  <S.FeedbackHeader isSuccess={isSuccess}>
    {isSuccess ? <SuccessIcon /> : <FailureIcon />}
    {title ? <S.FeedbackTitle>{title}</S.FeedbackTitle> : <S.TextLoading />}
  </S.FeedbackHeader>
)

export default FeedbackHeaderCheckout
