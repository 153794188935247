import { useMemo } from 'react'
import { ButtonV3 as Button } from '@provi/provi-components'
import { usePreApprovedContent } from './hooks'
import { addMask } from '~/utils/addMask'
import * as S from './style'

const PreApprovedContent = () => {
  const { infoGuarantor, handleSubmitButton, isLoadingData, isSendingData } = usePreApprovedContent()

  const renderTextsPreApprovedGuarantor = useMemo(() => {
    const cpfFormatted = addMask(infoGuarantor?.CPF || '', '###.###.###-##')

    if (infoGuarantor) {
      return (
        <>
          <S.PreApprovedTitle>Seu financiamento foi pré-aprovado!</S.PreApprovedTitle>
          <S.PreApprovedSubTitle>A pessoa avalista que você indicou foi aceita:</S.PreApprovedSubTitle>

          <S.InfoGuarantor>
            <S.PreApprovedSubTitle data-recording-sensitive>{infoGuarantor?.fullName}</S.PreApprovedSubTitle>
            <S.PreApprovedSubTitle data-recording-sensitive>
              <strong>CPF:</strong> {cpfFormatted}
            </S.PreApprovedSubTitle>
          </S.InfoGuarantor>

          <S.PreApprovedSubTitle>Na próxima etapa, precisaremos de uma foto do seu rosto.</S.PreApprovedSubTitle>
        </>
      )
    }

    return (
      <>
        <S.PreApprovedTitle>Seu financiamento foi pré-aprovado</S.PreApprovedTitle>
        <S.PreApprovedSubTitle>Na próxima etapa, precisaremos de uma foto do seu rosto.</S.PreApprovedSubTitle>
      </>
    )
  }, [infoGuarantor])

  const renderResultGuarantorData = useMemo(() => {
    if (isLoadingData && process.env.NODE_ENV !== 'test') {
      return (
        <S.InfoGuarantor>
          <S.DataInputLoading className="loading-title" />

          <S.DataInputLoadingWrapper>
            <S.DataInputLoading />
            <S.DataInputLoading />
          </S.DataInputLoadingWrapper>
        </S.InfoGuarantor>
      )
    }
    return renderTextsPreApprovedGuarantor
  }, [isLoadingData, renderTextsPreApprovedGuarantor])

  return (
    <S.Wrapper>
      <S.InfoGuarantorWrapper>{renderResultGuarantorData}</S.InfoGuarantorWrapper>
      <Button text="Continuar" icon type="submit" onClick={handleSubmitButton} isLoading={isSendingData} />
    </S.Wrapper>
  )
}

export default PreApprovedContent
