import Link from 'next/link'
import * as S from './styles'

import { useContext } from 'react'
import { Svg } from '~/components/atoms'
import { AddressProvi, ContactProvi, DataProvi } from '~/components/organisms'
import { ParamsContext } from '~/contexts/ParamsContext'
import { useIFrameParams } from '~/hooks/useIFrameParams'

interface IFooter {
  isInCheckout: boolean
  isInIframe: boolean
}

const Footer = ({ isInCheckout, isInIframe }: IFooter) => {
  const { route, router } = useContext(ParamsContext)
  const { iframeParams } = useIFrameParams()
  const paddingVariant = route === '/courses/[slug]' && !!router.query?.classes?.length
  const currentYear = new Date().getFullYear()

  if (isInIframe && iframeParams?.support) {
    return (
      <div className="hidden md:flex fixed bottom-6 right-6 w-12 h-12 bg-brand-primary rounded-full items-center justify-center text-white cursor-pointer z-50">
        <Link
          href="https://api.whatsapp.com/send?phone=5511957712414&text=(PrincipiaPay) Oi, Principia! Pode me ajudar?"
          passHref
        >
          <a target="_blank" rel="noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="var(--white100)" viewBox="0 0 256 256">
              <path d="M187.58,144.84l-32-16a8,8,0,0,0-8,.5l-14.69,9.8a40.55,40.55,0,0,1-16-16l9.8-14.69a8,8,0,0,0,.5-8l-16-32A8,8,0,0,0,104,64a40,40,0,0,0-40,40,88.1,88.1,0,0,0,88,88,40,40,0,0,0,40-40A8,8,0,0,0,187.58,144.84ZM152,176a72.08,72.08,0,0,1-72-72A24,24,0,0,1,99.29,80.46l11.48,23L101,118a8,8,0,0,0-.73,7.51,56.47,56.47,0,0,0,30.15,30.15A8,8,0,0,0,138,155l14.61-9.74,23,11.48A24,24,0,0,1,152,176ZM128,24A104,104,0,0,0,36.18,176.88L24.83,210.93a16,16,0,0,0,20.24,20.24l34.05-11.35A104,104,0,1,0,128,24Zm0,192a87.87,87.87,0,0,1-44.06-11.81,8,8,0,0,0-6.54-.67L40,216,52.47,178.6a8,8,0,0,0-.66-6.54A88,88,0,1,1,128,216Z"></path>
            </svg>
          </a>
        </Link>
      </div>
    )
  }

  if (isInIframe) {
    return null
  }

  return (
    <S.FooterContainer isVariant={paddingVariant} isInCheckout={isInCheckout}>
      <S.FooterContent>
        <Svg src="/assets/png/logo-principiapay.png" alt="logo Principiapay" width={125} height={24} loading="lazy" />
        <DataProvi
          companyName={`© ${currentYear} PrincipiaPay Educação Tecnologia e Serviços Ltda`}
          cnpj="CNPJ 32.390.384/0001-92"
        />
        <AddressProvi companyAddress="Av. Dr. Cardoso de Melo, 1340 cj. 11" city="Vila Olímpia, São Paulo/SP" cep="04548-004" />
        <ContactProvi />
      </S.FooterContent>
    </S.FooterContainer>
  )
}

export default Footer
