import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

interface IUseBarteScript {
  sensitiveFields?: string[]
}

export const useBarteScript = ({
  sensitiveFields = ['cardName', 'cardCvv', 'cardExpireDate', 'cardNumber'],
}: IUseBarteScript) => {
  const [attemptReference] = useState(uuidv4())
  const [idempotencyKey] = useState(uuidv4())

  useEffect(() => {
    if (typeof window === 'undefined') return

    if (attemptReference) {
      const nethoneScript = document.createElement('script')
      nethoneScript.type = 'text/javascript'
      nethoneScript.id = 'nethone-script'
      nethoneScript.crossOrigin = 'use-credentials'
      nethoneScript.src = 'https://d354c9v5bptm0r.cloudfront.net/s/68741/dQItJr.js'
      nethoneScript.async = true
      document.body.appendChild(nethoneScript)

      nethoneScript.onload = () => {
        localStorage.setItem('attempt_reference', attemptReference)
        const nethoneOptions = {
          attemptReference: attemptReference,
          sensitiveFields,
        }
        // @ts-ignore
        if (window?.dftp) {
          // @ts-ignore
          window?.dftp?.init?.(nethoneOptions)
        } else {
          nethoneScript.addEventListener('load', () => {
            // @ts-ignore
            if (window?.dftp) {
              // @ts-ignore
              window?.dftp?.init?.(nethoneOptions)
            }
          })
        }
      }
    }
  }, [attemptReference])

  return { attemptReference, idempotencyKey }
}
